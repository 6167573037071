import FormInput from "app/SharedComponents/FormInput";
import { fetchData } from "app/services/fetchService";
import config from "config";

const CheckoutPayment = ({formControl, inputChange, prevStep, deliveryType}) => {

const onPay = async (e)=>{
  e.preventDefault();
  const cart = JSON.parse(localStorage.getItem('cart'));


  const {cardHoderName, cardNumber, cardExpiration, cardCVV, ...mainObj} = formControl;
  if(!deliveryType){
    mainObj.country = '';
    mainObj.address = '';
    mainObj.city = '';
    mainObj.postCode = '';
    mainObj.isPostDelivery = false;
  }else if(deliveryType === "SHIP"){
    mainObj.isPostDelivery = true;
  }

  const productIds = cart.map(item=>item._id);

  const requestBody = {
    customerData: mainObj,
    productIds
  }


  onlinePayment(requestBody);
}

const onlinePayment = (requestBody)=>{
  const url = `${config.apiUrl}/orders/checkout`;

    fetchData(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    }).then(session=>{
      window.open(
        session.url,
        '_blank'
      );
    })
    .catch(e=>{
      console.log("err")
    })
    
}

  return (
    <div className="page page__3">
      <div className="pageContent">
        <form onSubmit={onPay}>
          <div className="choosePayment">
            <label className="radioBtn">
              <input  type="radio" name="payType" onChange={inputChange} value="Card" required /> Card
            </label>
            <label className="radioBtn">
              <input type="radio" name="payType" onChange={inputChange} value="Twint" required /> Twint
            </label>

          </div>

          <FormInput value={formControl.cardHoderName} name="cardHoderName" onChange={inputChange}  placeholder="Cardholder Name*" className="fullWidth"  required />
          <FormInput value={formControl.cardNumber} name="cardNumber" onChange={inputChange}  placeholder="Card Number*" className="fullWidth" required/>
          <FormInput value={formControl.cardExpiration} name="cardExpiration" onChange={inputChange}  placeholder="Expiration Date" required/>
          <FormInput value={formControl.cardCVV} name="cardCVV" onChange={inputChange}  placeholder="CVV*" required/>

          <div className="pageActions">
            <button>Pay</button>
            <button onClick={prevStep} type="button" className="btnTransparent">Back</button>
          </div>
        </form>

      </div>
    </div>
  );
};

export default CheckoutPayment;
