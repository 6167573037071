import FooterImage from 'images/footer/FooterImage.webp';
import Instagram from 'images/icons/instagram.svg'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
const Footer = () => {
    const { t } = useTranslation();

    return ( 
        <footer>
            <div className='footer-wrap container'>
                <div className='footer--contact'>
                    <ul>
                        <li className='listHead'>{t('Opening_Hours')}</li>
                        <li>{t('Wednesday-Saturday')}</li>
                        <li>11:00-18:00</li>
                    </ul>
                    <ul>
                        <li className='listHead'>{t('Contact')}</li>
                        <li><a href="tel:079-908-49-89">079 908 49 89</a></li>
                        <li>contact@leplongeoir.ch</li>
                        <li><img src={Instagram} alt="Icon" /><a href="https://www.instagram.com/leplongeoir.ch/?igsh=MTE5YXA0dzFtZjdraQ%3D%3D" target="_blank">leplongeoir.ch</a></li>
                    </ul>
                </div>

                <img className='footerImage' src={FooterImage} alt="FooterImg" />

                <nav>
                    <ul>
                        <li><NavLink to={'/shop'}>{t('shop')}</NavLink></li>
                        <li><NavLink to={'/laButique'}>LA BOUTIQUE</NavLink></li>
                        <li><NavLink to={'/our_story'}>{t('our_story')}</NavLink></li>
                        <li><NavLink to={'/la_plongeuse'}>LA PLONGEUSE</NavLink></li>
                    </ul>
                </nav>
            </div>
        </footer>
     );
}
 
export default Footer;