import MinusIcon from 'images/icons/minus.svg';
import PlusIcon from 'images/icons/plus.svg';
import { useState } from 'react';

const InfoExpanderV2 = ({title, initialState = false, children}) => {

    const [expanded, toggleExpand] = useState(initialState)
    // let expanded = false;

    const expandInfo = ()=>{
        toggleExpand(!expanded)
    }


    return ( 
        <div className="info-expander">
            <div onClick={expandInfo} className="expander-main">
                <p>{title}</p>


                {expanded ? <img src={MinusIcon} alt="-" /> : <img src={PlusIcon} alt="+" />}
            </div>

            <div className={`expander-content ${expanded ? 'active' : ''}`}>
                <div className='expander__expanded'>
                    {children}
                </div>
            </div>
        </div>
     );
}
 
export default InfoExpanderV2;