// import editIcon from 'images/icons/editIcon.svg';
import removeIcon from 'images/icons/icon_trash.svg';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { clothingTypeMulti } from 'globalVariables/globalVariables';
import config from 'config';
import { fetchData } from 'app/services/fetchService';

const AdminList = ({isSoldComponent}) => {
  const [products, setProducts] = useState([]);
  const [currentFilter, setFilter] = useState("ALL");
  const [productsTotal, setProductsTotal] = useState(0);
  const [currentPage, setPage] = useState(0);
  const accessToken = localStorage.getItem('token');




  const deleteItem = (id)=>{
    const response = window.confirm("Do you want to delete?");
    if(!response){return}
    deleteRequest(id);
  }

  const deleteRequest = (id)=>{
    const url = `${config.apiUrl}/products/${id}`;


    fetchData(
      url, 
      { 
        method: 'DELETE',
        headers:{
          'Authorization': `Bearer ${accessToken}`,
        },
      }).then(res=>{
        alert("Deleted!");
        const newProducts = products.filter((item=>item._id !== id));
        setProducts(newProducts);
      })
  }

  const nextPage = ()=>{
    setPage(currentPage+1);
  }

 
  useEffect(() => {
    getProducts();
  }, [currentFilter, currentPage, isSoldComponent]);

  const getProducts = ()=>{
    const accessToken = localStorage.getItem('token');
    const url = `${config.apiUrl}/products/admin/getProducts?isSoldComp=${isSoldComponent}&clothingType=${currentFilter}&page=${currentPage}`;
    
    fetchData(url, {
      headers:{
        'Authorization': `Bearer ${accessToken}`,
      },
    })
      .then(data => { 
        setProductsTotal(data.count);
        if(currentPage===0){
          setProducts(data.products);
        }else{
          setProducts([...products, ...data.products]);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const filterProducts = (filterBy) => {
    setFilter(filterBy);
    setPage(0);
  };

  return (
    <div id="adminList" className="container">
      <div className="adminList__header">
        <h2>{currentFilter}</h2>
        <div className="adminList__filter">
          {clothingTypeMulti.map((clothType) => (
            <p className={`${currentFilter === clothType.en && "active"}`} key={clothType.en} onClick={() => { filterProducts(clothType.en) }}>{clothType.en}</p>
          ))}
        </div>
      </div>

      <div className="adminList__body">
        <div className="list__title">
          <p>Photo</p>
          <p>Type</p>
          <p>Brand</p>
          <p>Color</p>
          <p>Price</p>
          <p>Status</p>
          <p className='addItem'>
          <Link to={'/admin/create'}>
            <button>
              Add Item
            </button>
            </Link>
          </p>
        </div>

        <div className="admin__list__wrap">
          {products && products.map((product, index) => (
            <div className="list__cell" key={product._id}>
              <div className="list__cell__section">
                <img src={product.images[0]} alt="Product" />
              </div>
              <div className="list__cell__section">
                <p className="itemType">{product.clothingType}</p>
              </div>
              <div className="list__cell__section">
                <p className="itemBrand">{product.brand}</p>
              </div>
              <div className="list__cell__section">
                <p className="itemColor">{product.color}</p>
              </div>
              <div className="list__cell__section">
                <p className="itemPrice">{product.price} CHF</p>
              </div>
              <div className="list__cell__section">
                <p className="itemPrice">{product.status}</p>
              </div>
              <div className="list__cell__section list__cell__actions">
                {/* <img src={editIcon} alt="edit" /> */}
                <img onClick={()=>{deleteItem(product._id)}} src={removeIcon} alt="remove" />
              </div>
            </div>
          ))}
        </div>
          {
            productsTotal > products.length ?
            <button onClick={nextPage}>Show more</button>
            :
            null
          }
        
      </div>
    </div>
  );
};

export default AdminList;