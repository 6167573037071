import { useNavigate } from 'react-router-dom';
import config from 'config';
import { useContext, useState } from 'react';
import { AuthContext } from 'app/Context/AuthContext';
import { fetchData } from 'app/services/fetchService';
import AppButton from 'app/SharedComponents/App_Button';


const Login = () => {
    const navigate = useNavigate();
    const { dispatch } = useContext(AuthContext);
    const [ loading , setLoading ] = useState(false);


    const login = (event)=>{
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.target);
        const data = JSON.stringify(Object.fromEntries(formData.entries()));
        const url = `${config.apiUrl}/auth/login`;
        
        fetchData(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },    
            body: data,
        })
        .then(res=>{
            dispatch({ type: 'LOGIN', payload: true });
            localStorage.setItem('token', res.token)
            navigate('/admin/itemList');
        })
        .finally(()=>{
            setLoading(false)
        })
    }

    return ( 
        <div id="adminLogin" className="container">
            <h1>Login</h1>
            <form onSubmit={login}>
                <input type="text" placeholder="userName" name="userName" required />
                <input type="password" placeholder="password" name="password" required />
                <AppButton loading={loading}>Login</AppButton>
            </form>
        </div>
     );
}
 
export default Login;