import logo from 'images/icons/logo.svg';
import { useEffect, useState } from 'react';
import CheckoutContact from './CheckoutContact';
import CheckoutDelivery from './CheckoutDelivery';
import CheckoutPayment from './CheckoutPayment';
import checkIcon from 'images/icons/checkIcon.svg'


const CheckoutPage = () => {

    const [currentStep, setStep] = useState(3);

    const [formControl, setControls] = useState({
        firstName: "asd",
        lastName: "asd",
        phoneNumber: "asd",
        emailAddress: "gio@gmail.com",
        
        // isPostDelivery: false, 
        
        country: "ads",
        address: "asd",
        city: "asd",
        postCode: "asd",

        payType: "Twint",
        cardHoderName: "asd",
        cardNumber: "asd",
        cardExpiration: "asd",
        cardCVV: "asd"
    });

    const [deliveryType, setDeliveryType] = useState();


    const inputChange = (e)=>{
        setControls({...formControl, [e.target.name] : e.target.value})
    }

    const nextStep = ()=>{
        let step = currentStep + 1;
        setStep(step);
    }

    const prevStep = ()=>{
        let step = currentStep - 1;
        setStep(step);
    }

    return ( 
        <>
            <div id="checkout">
                <div className='checkout__wrap'>
                    <div className='checkout__form__container'>
                        <div className='checkout__form__wrap'>
                            <img src={logo} alt="" />
                            <div className='stepper'>
                                <img style={{display: 'none'}} src={checkIcon} alt='V'/>
                                {currentStep > 1 ? <img loading='eager' src={checkIcon} alt='V'/> : <p className={`stepper__step  ${currentStep === 1 && 'active'}`}><span className='stepper__step__number'>1</span> <span>Contact</span></p>}
                                {currentStep > 2 ? <img src={checkIcon} alt='V'/> : <p className={`stepper__step  ${currentStep === 2 && 'active'}`}><span className='stepper__step__number'>2</span> <span>Delivery</span></p>}
                                {currentStep > 3 ? <img src={checkIcon} alt='V'/> : <p className={`stepper__step  ${currentStep === 3 && 'active'}`}><span className='stepper__step__number'>3</span> <span>Payment</span></p>}
                            </div>

                            <h1>Enter Your Details</h1>

                            <div className='stepper__page__container'>

                                {   currentStep === 1 &&  <CheckoutContact nextStep={nextStep} inputChange={inputChange} formControl={formControl}/>    } 

                                {   currentStep === 2 && <CheckoutDelivery nextStep={nextStep} prevStep={prevStep} inputChange={inputChange} formControl={formControl} deliveryType={deliveryType} setDeliveryType={setDeliveryType}/>    }

                                {   currentStep === 3 && <CheckoutPayment nextStep={nextStep} prevStep={prevStep} inputChange={inputChange} formControl={formControl} deliveryType={deliveryType}/>     }
                         
                            </div>
                        </div>
                    </div>

                    <div className='checkout__items__container'>
                        <div className='checkout__items__wrap'>
                            <div className='checkout__item__cell'>
                                <div className='item__details'>
                                    <img src={''} alt="img" />
                                    <div className='item__details__text'>
                                        <div>
                                            <h6 className='name'>Brown Suit</h6>
                                            <p className='size'><span>Size: </span>M</p>
                                            <p className='brand'><span>Brand: </span>Hand-made Anonymous</p>
                                        </div>
                                        <p className='price'>198 CHF</p>
                                    </div>
                                </div>
                                <p className='subtotal'><span>Sub-total</span> <span className='price'>198CHF</span></p>
                            </div>
                            <p className='total'><span>Total</span> <span className='price'>198CHF</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
     );
}
 
export default CheckoutPage