import FormInput from "app/SharedComponents/FormInput";

const CheckoutDelivery = ({deliveryType, setDeliveryType, formControl, inputChange, nextStep, prevStep}) => {

  const handleBack = (e) =>{
    if(!deliveryType){
      prevStep();
    }else{
      setDeliveryType()
    }
  }

  const handleNext = (e) => {

    e.preventDefault();
    if(!deliveryType){
      const deliveryChosen = new FormData(e.target).get("deliveryType");
      if(deliveryChosen === "PICKUP"){
        nextStep();
      }else if(deliveryChosen ==="SHIP"){
        setDeliveryType("SHIP")
      }
    }

    if(deliveryType === "SHIP"){
      nextStep();
    }
  };

  return (
    <div className="page page__2">
      <div className="pageContent">
      <form onSubmit={handleNext}>
          {!deliveryType && (
            <>
              <label className="radioBtn">
                <input type="radio" name="deliveryType" value="SHIP" required />
                Post Delivery
                <p className="deliveryPrice">8 CHF</p>
              </label>
              <label className="radioBtn">
                <input type="radio" name="deliveryType" value="PICKUP" required />
                Next day pick up at our shop
                <p className="deliveryPrice">Free</p>
              </label>
            </>
          )}

          {deliveryType === "SHIP" && (
            <>
              <FormInput
                value={formControl.country}
                name="country"
                placeholder="Country/region*"
                className="fullWidth"
                onChange={inputChange}
                required
              />
              <FormInput
                value={formControl.address}
                name="address"
                placeholder="Address*"
                className="fullWidth"
                onChange={inputChange}
                required
              />
              <FormInput value={formControl.city} name="city" placeholder="City*" onChange={inputChange} required />
              <FormInput value={formControl.postCode} name="postCode" placeholder="Post Code*" onChange={inputChange} required />
            </>
          )}
          <div className="pageActions">
            <button>Continue</button>
            {/* <button className="btnTransparent">Collect in our boutique</button> */}
            <button className="btnTransparent" type="button" onClick={handleBack}>Back</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CheckoutDelivery;
