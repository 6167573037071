import Catalogue from "app/mainApp/HomePage/Catalogue";
import { fetchData } from "app/services/fetchService";
import config from "config";
import { useEffect, useState } from "react";
import AddCatalogue from "./addCatalogue";
import AdminCatalogueCell from "./adminCatalogueCell";

const AdminCatalogue = () => {
  const [catalogues, setCatalogues] = useState(null);

  useEffect(() => {
    getAllCatalogues();
  }, []);

  const getAllCatalogues = () => {
    const url = `${config.apiUrl}/catalogue/getAll`;
    fetchData(url).then((items) => {
      setCatalogues(items);
    });
  };





  return (
    <div className="adminCatalogue container">
      <div className="catalogue--list">
        {catalogues &&
          catalogues.map((item, index) => (
            <AdminCatalogueCell key={index} item={item} onPostSuccess={getAllCatalogues}/>
          ))}
      </div>

      <AddCatalogue onPostSuccess={getAllCatalogues}></AddCatalogue>
    </div>
  );
};

export default AdminCatalogue;
