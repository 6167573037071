import { type } from "@testing-library/user-event/dist/type";
import { CartContext } from "app/Context/CartContext";
import { fetchData } from "app/services/fetchService";
import config from "config";
import closeImg from "images/icons/close.svg";
import trashIcon from "images/icons/icon_trash.svg";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AppButton from "./App_Button";

const Cart = ({ closeOverlay }) => {
  const { i18n, t } = useTranslation();
  const { cart, dispatch } = useContext(CartContext);
  const [ loading , setLoading ] = useState(false);
  const [ loadingPickup , setLoadingPickup ] = useState(false);

  const removeFromCart = (id) => {
    dispatch({ type: "REMOVE_FROM_CART", payload: id });
  };

  const clearCart = ()=>{
    dispatch({ type: "CLEAR_CART"});
  }

  const PostDelivert = async () => {
    console.log("123")
    if(!cart.length)return;
    setLoading(true);
    await onlinePayment(true);
    clearCart();
    closeOverlay();
  };

  const PickupInStore = async ()=> {
    console.log("AQAA")
    if(!cart.length)return;
    console.log("WAT")
    setLoadingPickup(true);
    await onlinePayment(false);
    clearCart();
    closeOverlay();
  }

  
const onlinePayment = async (isShipping)=>{
  console.log("AQEDAN!");
  const language = localStorage.getItem('language')
  const productIds = cart.map(item=>item._id);
  const requestBody = JSON.stringify({
    productIds,
    isShipping,
    language
  });
  const url = `${config.apiUrl}/orders/checkout`;

    await fetchData(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: requestBody
    })
    .then(session=>{
      console.log("AQEDAN AKETEBS")
      window.open(
        session.url,
        "_self"
      );
    })
    .catch(e=>{
      console.log("err",e)
    })
    .finally(()=>{
      setLoading(false);
      setLoadingPickup(false);
    })
    
}

  const calculateSum = cart.reduce(
    (accumulator, product) => accumulator + product.price,
    0
  );

  return (
    <div id="CartComponent">
      <div className="cart__header">
        <h3>{t('your_cart')}</h3>
        <img onClick={closeOverlay} src={closeImg} alt="X" />
      </div>

      <div className="cartBody">
        <div className="itemList">
          {cart &&
            cart.map((data) => (
              <div key={data._id} className="checkout__item__cell">
                <div className="image__wrap">
                  <img src={data.images && data.images[0]} alt="img" />
                </div>
                <div className="item__details__text">
                  <div>
                    <h6 className="name">{i18n === "en" ? data.name : data.name__french}</h6>
                    <p className="size">
                      <span>{t('Size')} </span>
                      {data.size}
                    </p>
                    <p className="brand">
                      <span>{t('Brand')} </span>
                      {data.brand}
                    </p>
                  </div>
                  <p className="price">{data.price} CHF</p>
                </div>
                <img
                  onClick={() => {
                    removeFromCart(data._id);
                  }}
                  className="removeItem"
                  src={trashIcon}
                  alt="X"
                />
              </div>
            ))}
        </div>
        <p className="total">
          <span>Total</span> <span className="price">{calculateSum}CHF</span>
        </p>
      </div>

      <div className="cartActions">
        <AppButton onclick={PostDelivert} loading={loading}>{t('Post_Delivery')}</AppButton>
        <AppButton onclick={PickupInStore} loading={loadingPickup}>{t('Pickup_In_Store')}</AppButton>
      </div>
    </div>
  );
};

export default Cart;
