import CreateListing from "./CreateListing";
import AdminHeader from "./adminHeader";
import AdminList from "./adminList";
import { Outlet } from "react-router-dom";

const AdminPage = () => {
    return ( 
        <>
            <AdminHeader></AdminHeader>
            <Outlet/> 
        </>
     );
}
 
export default AdminPage;