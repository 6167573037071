const AppButton = ({ children, loading, onclick }) => {

    return ( 
        <button onClick={onclick} disabled={loading} >
            {
                !loading ? children : <div className="loader"></div>
            }
        </button>
     );
}
 
export default AppButton;