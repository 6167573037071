import AppButton from "app/SharedComponents/App_Button";
import { fetchData } from "app/services/fetchService";
import config from "config";
import { useState } from "react";

const AdminCatalogueCell = ({ item, onPostSuccess }) => {
  const [editing, setEditing] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoding] = useState(false);

  const onCancel = () => {
    setSelectedImage(null);
    setEditing(null);
    setFile(null);
  };

  const onSave = async () => {
    if(!file){
      return alert("Image is not uploaded");
    }
    setSaveLoding(true);

    const url = `${config.apiUrl}/catalogue`;
    const accessToken = localStorage.getItem('token');

    const formData = new FormData();
    console.log(item);
    formData.append('file', file);
    formData.append('id', item._id);
    formData.append('imageName', item.name);

    fetchData(url, {
        body: formData,
        headers:{
            'Authorization': `Bearer ${accessToken}`,
        },
        method: 'PUT',
    })
    .finally(()=>{
      setSelectedImage(null);
      setEditing(null);
      setSaveLoding(false);
      setFile(null);
      onPostSuccess();
    })
  };

  const onEdit = (itemName) => {
    setEditing(itemName);
  };

  const onDelete = (id) =>{
    console.log("OMDE")
    const response = window.confirm("Do you want to delete?");
    if(!response){return}
    setDeleteLoading(true);


    const url = `${config.apiUrl}/catalogue`;
    const accessToken = localStorage.getItem('token');

    fetchData(url, {
        body: JSON.stringify({id}),
        headers:{
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        method: 'DELETE',
    }).then(()=>{
        onPostSuccess();
    }).finally(()=>{
        setDeleteLoading(false);
    })
  }

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];
      setSelectedImage(URL.createObjectURL(imageFile));
      setFile(imageFile); // Save the file for upload
    }
  };

  return (
    <div className="catalogue--cell">
      <div className="imageContainer">
        {editing !== item.name && <img src={item.img} alt="sa" />}
        {editing === item.name && <img src={selectedImage} alt="as" />}
      </div>

      <p>{item.name}</p>
      <p>{item.nameFr}</p>

      {!editing && (
        <div className="mainActions">
          <button
            onClick={() => {
              onEdit(item.name);
            }}
          >
            edit
          </button>

            <AppButton onclick={()=>onDelete(item._id)} loading={deleteLoading} >Delete</AppButton>
        </div>
      )}
      {editing === item.name && (
        <>
          <input type="file" accept="image/*" onChange={handleImageChange} />
          <div className="mainActions">
            <button onClick={onCancel}>Cancel</button>
            <AppButton onclick={onSave} loading={saveLoading}>Save</AppButton>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminCatalogueCell;
