import logo from "images/icons/logo.svg";
import Footer from "app/SharedComponents/Footer";
import { useEffect, useState } from "react";
import { fetchData } from "app/services/fetchService";
import config from "config";
import { NavLink, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ThankYouPage = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState();
  const { i18n, t } = useTranslation();
  
  useEffect(()=>{
    const ln = localStorage.getItem('language') || 'en';
    i18n.changeLanguage(ln);
  },[])

  useEffect(() => {
    const paramValue = searchParams.get("session_id");
    console.log("a", paramValue);
    const url = `${config.apiUrl}/orders/checkout-session/${paramValue}`;
    fetchData(url)
      .then((val) => {
        setData(val);
      })
      .catch((v) => {
        alert(v);
      });
  }, []);

  return (
    <>
      <div id="thankYou" className="container">
        <NavLink to="/"><img src={logo}  alt="logo" /></NavLink>


        <div className="thankYou__wrap">
          <h1>{t('Thank you for your order')}</h1>

          {data && (
            <>
              <div className="itemsList">
                {
                    data.products.map(element => 
                        (
                        <div className="itemsCell">
                        <img src={element.images[0]} alt="item" />
                        <div className="itemCell__info">
                          <div className="mainInfo">
                            <h6>{element.name}</h6>
                            {/* <p className="size">
                              <span>Size: </span>M
                            </p> */}
                            {/* <p className="brand">
                              <span>Brand: </span>Hand-made Anonymous
                            </p> */}
                          </div>
                          <div className="priceWrap">
                            <p className="price">{element.price/100} CHF</p>
                          </div>
                        </div>
                      </div>
                    ))
                }

              </div>

              <div className="ShopMainInfo">
                <div className="contactInfo">
                  <h6>{t('Contact Info')}</h6>

                  <p>{data.customer_details.name}</p>
                  <p>{data.customer_details.phone}</p>
                  <p>{data.customer_details.email}</p>
                  <p>{data.customer_details.address.line1}</p>
                  <p>{data.customer_details.address.city}</p>
                </div>

                <div className="priceInfo">
                  <p className="priceCell subtotal">
                    <span>{t('Sub-Totals')}</span>
                    <span className="price">{data.subTotal}CHF</span>
                  </p>
                  {data.total !== data.subTotal && (
                    <p className="priceCell subtotal">
                      <span>Post delivery</span>
                      <span className="price">{data.total - data.subTotal}CHF</span>
                    </p>
                  )}

                  <p className="priceCell total">
                    <span>Total</span>{" "}
                    <span className="price priceTotal">{data.total}CHF</span>
                  </p>
                </div>
              </div>
            </>
          )}
          {/* <button>Main Page</button> */}
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default ThankYouPage;
