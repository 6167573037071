import { useState } from "react";

const FormInput = (props) => {
    const [touched, setTouched] = useState(false);

    return ( 
    <div className={`formInput ${props.className}`}>
        <input type="text" {...props} onBlur={()=>setTouched(true)} touched={touched.toString()}/>
        <span>Required</span>
    </div>
     );
}
 
export default FormInput;