import AppButton from "app/SharedComponents/App_Button";
import { fetchData } from "app/services/fetchService";
import config from "config";
import { useRef, useState } from "react";

const AddCatalogue = ({onPostSuccess}) => {
    const fileInputRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);


    const uploadCatalogue = async (event)=>{
        event.preventDefault();
        setLoading(true)


        const url = `${config.apiUrl}/catalogue/uploadCatalogue`;
        const accessToken = localStorage.getItem('token');
        const formData = new FormData(event.target);


        await fetchData(url, {
            method: 'POST', 
            headers:{
              'Authorization': `Bearer ${accessToken}`,
            },
            body: formData
          })
          .then(()=>{
            event.target.reset();
            setSelectedImage(null);
            onPostSuccess();
          })
          .catch((e)=>{
            console.log(e);
          }).finally(()=>{
            setLoading(false);
          })
    }

    const addImage = ()=>{
        fileInputRef.current.click();
    }

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const imageFile = event.target.files[0];
            setSelectedImage(URL.createObjectURL(imageFile));
          }
      };

    return ( 
        <div className="addCatalogue">
            <form action="" onSubmit={uploadCatalogue}>
                <div className="imageContainer" onClick={addImage}>
                    <img src={selectedImage} alt=""/>
                    <input ref={fileInputRef} type="file" name="file" onChange={handleFileChange} />
                </div>
                <input type="text" placeholder="name" name="name" required/>
                <input type="text" placeholder="nameFr" name="nameFr" required/>
                <AppButton loading={loading}>Create</AppButton>
            </form>
        </div>
     );
}
 
export default AddCatalogue;