import Header from "app/SharedComponents/Header";
import Footer from "app/SharedComponents/Footer";
import { Outlet } from "react-router-dom";
import { CartProvider } from "app/Context/CartContext";

const MainApp = () => {
  return (
      // <CartProvider>
      //   <Header/>
      //   <main> 
      //     <Outlet/> 
      //   </main>
      //   <Footer/>
      // </CartProvider>
      <div className="underMaintenance">
        Under Maintenance
      </div>
  );
};

export default MainApp;
