import FormInput from "app/SharedComponents/FormInput";

const CheckoutContact = ({formControl, inputChange, nextStep}) => {
    const handleNext = (e)=>{
        e.preventDefault();
        nextStep();
    }
    
    return ( 
        <div className='page page__1'>
            <div className='pageContent'>
                <form onSubmit={handleNext}>
                    <FormInput value={formControl.firstName} name="firstName" onChange={inputChange} placeholder="First Name*" required/>
                    <FormInput value={formControl.lastName} name="lastName" onChange={inputChange} placeholder="Last Name*" required/>
                    <FormInput value={formControl.phoneNumber} name="phoneNumber" onChange={inputChange} placeholder="Phone*" required/>
                    <FormInput value={formControl.emailAddress} name="emailAddress" onChange={inputChange} placeholder="Email Address*" type="email" required/>

                    <div className='pageActions'>
                        <button>Continue</button>
                    </div>
                </form>
            </div>


        </div>
     );
}
 
export default CheckoutContact;