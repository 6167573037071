import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './app/SharedComponents/shared.scss'
import App from './App';
import './i18n';
import { AuthContextProvider } from 'app/Context/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthContextProvider>
        <App />
    </AuthContextProvider>
);