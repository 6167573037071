import { useEffect, useState } from "react";
import { Blurhash } from "react-blurhash";

const ImageComponent = ({src, blurhash}) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(()=>{
        const img = new Image();

        img.onload = ()=>{
            setImageLoaded(true);
        }

        img.src = src;
    },[src])

    return ( 
        <>
            {
                (
                    !imageLoaded && blurhash.length>=6 && <Blurhash
                    hash= {blurhash}
                    width="100%"
                    height="100%"
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                    />
                )
            }

            {
                imageLoaded && (
                    <img src={src} alt="our_story" />
                )
            }

        </>
     );
}
 
export default ImageComponent;